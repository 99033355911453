<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
			@click.stop="row.toggleDetails" class="mr-1 mt-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>

		<b-button v-show="status === 'Draft' && !isViewer" size="sm" v-b-tooltip.hover.top="'Edit Details'" variant="dark"
			@click.stop="editDispatch(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<i class="fa fa-pencil"></i>
		</b-button>

		<b-button v-show="status === 'Draft' && !isViewer" size="sm" v-b-modal.deploy-dispatch
			v-b-tooltip.hover.top="'Deploy Dispatch'" variant="primary"
			@click.stop="updateSelDispatch(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<i class="fa fa-share"></i>
		</b-button>

		<b-button v-show="isAllowedToCancel(row.item)" size="sm" v-b-modal.cancel-dispatch
			v-b-tooltip.hover.top="'Cancel Dispatch'" variant="danger"
			@click.stop="updateSelDispatch(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<em class="fa fa-ban"></em>
		</b-button>

		<b-button v-show="isAllowedToReceive(row.item)" size="sm" v-b-modal.receive-dispatch
			v-b-tooltip.hover.top="'Receive Dispatch'" variant="primary" @click.stop="updateSelDispatch(row.item)"
			class="mr-1 mt-1">
			<b-icon icon="box-arrow-in-down"></b-icon>
		</b-button>

		<!-- TODO: Remove push pull button. Will be included once push pull once done implemented -->

		<b-button size="sm" v-b-modal.print-dispatch-summary v-b-tooltip.hover.top="'Print Dispatch Summary'" variant="dark"
			@click.stop="updateSelDispatch(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<em class="icon-printer"></em>
		</b-button>
	</div>
</template>

<script>
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';

export default {
	name: 'dispatch-row-actions',
	props: {
		row: {
			type: Object,
			required: true,
		},
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true,
		},
		isManager: {
			type: Boolean,
			required: true,
		},
		isSupervisor: {
			type: Boolean,
			required: true,
		},
		parentComponent: {
			type: String,
			require: true,
		},
	},
	data() {
		return {
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
		};
	},
	computed: {
		status() {
			return this.row && this.row.item.status;
		},
		destinationCompanyId() {
			return this.row && this.row.item.destination.companyId;
		},
		sourceCompanyId() {
			return this.row && this.row.item.source.companyId;
		},
		currCompanyId() {
			return this.loggedUserCompany.id;
		},
	},
	methods: {
		editDispatch(item, _index, _button) {
			// init parameters
			this.params = this.$store.getters.dispatchParams;
			this.params.selDispatch = item;

			this.$store.dispatch('setDispatchParams', this.params);
			this.$store.dispatch('setCurrentDispatch', item);

			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-edit-dispatch' });
			} else if (!this.isSuperAdmin) {
				this.$router.push({ path: '/edit-dispatch' });
			} else {
				this.$toaster.warning('Invalid Source Screen');
			}
		},
		updateSelDispatch(item, _index, _button) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onUpdateSelDispatch', item);
		},
		isAllowedToCancel(dispatch) {
			return (
				(this.status === 'Draft' || this.status === 'In-Transit') &&
				(!this.isViewer && (this.isSuperAdmin || this.isManager || this.isSupervisor)) &&
				this.parentComponent === 'Dispatch'
			);
		},
		isAllowedToReceive(dispatch) {
			return (
				(this.status === 'In-Transit' || this.status === 'Receiving') &&
				(this.destinationCompanyId === this.currCompanyId ||
					(this.sourceCompanyId === this.currCompanyId &&
						dispatch.toInactiveNode === 'true')) &&
				!this.isViewer &&
				this.parentComponent === 'Receipt'
			);
		},
		isAllowedToPushPull(dispatch) {
			return (
				this.status === 'Received' &&
				!dispatch.donePushPull &&
				(this.destinationCompanyId === this.currCompanyId ||
					this.sourceCompanyId === this.currCompanyId) &&
				!this.isViewer &&
				this.parentComponent === 'Receipt'
			);
		},
	},
};
</script>

<style scoped></style>

