<template>
	<b-modal id="print-dispatch-summary" :title="title" size="xl" ok-title="Download" ref="modal" @ok="handleDownload"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-container v-show="selDispatch !== null">

			<div class="slide-container" v-show="currentSlide === 0" id="dispatch-summary-0">

				<b-row class="my-2">
					<b-col sm="6">
						<div class="dispatch-header">AYUN! DOCKET</div>
						<div class="dispatch-id">
							{{ selDispatch.dispatchId }}
						</div>
						<div class="dispatch-date">
							{{ showFormattedDate(selDispatch.dateCreated) }}
						</div>
					</b-col>
					<b-col sm="6" align="right">
						<img v-img-orientation-changer src="img/logo.png" class="img-logo" alt="Ayun! Logo" />
					</b-col>
				</b-row>

				<b-row class="my-4">
					<b-col sm="6">
						<b-row no-gutters>
							<b-col sm="2" class="section-header"> Truck </b-col>
							<b-col sm="10">
								<hr />
							</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Plate No/CR/OR: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								selDispatch.transportation
								? selDispatch.transportation.plateNo
								: ''
							}}</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Truck Company: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								selDispatch.transportation
								? selDispatch.transportation.company
								: ''
							}}</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Driver: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								selDispatch.driver
								? selDispatch.driver.name
								: ''
							}}</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Assistant(s): </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								selDispatch.driver
								? selDispatch.driver.assistants
								: ''
							}}</b-col>
						</b-row>
					</b-col>

					<b-col sm="6">
						<b-row no-gutters>
							<b-col sm="5" class="section-header"> Scan to view in App </b-col>
							<b-col sm="7">
								<hr />
							</b-col>
						</b-row>

						<b-row>
							<b-col sm="5" class="form-field"></b-col>
							<b-col sm="7" class="form-value-uppercase">
								<qr-code :size="100" :text="selDispatch.dispatchId" />
							</b-col>
						</b-row>
					</b-col>
				</b-row>

				<b-row class="my-4">
					<b-col sm="6">
						<b-row no-gutters>
							<b-col sm="2" class="section-header"> Source </b-col>
							<b-col sm="10">
								<hr />
							</b-col>
						</b-row>

						<b-row>
							<b-col sm="5" class="form-field"> Company: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								selDispatch.source ? selDispatch.source.company : ''
							}}</b-col>
						</b-row>
						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Warehouse: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								selDispatch.source ? selDispatch.source.storageLocation : ''
							}}</b-col>
						</b-row>
						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Address: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								sourceLocation.address
							}}</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Date Dispatched: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								showFormattedDate(selDispatch.dateDeployed)
							}}</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Status: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{ selDispatch.status }}</b-col>
						</b-row>

					</b-col>

					<b-col sm="6">
						<b-row no-gutters>
							<b-col sm="3" class="section-header"> Destination </b-col>
							<b-col sm="9">
								<hr />
							</b-col>
						</b-row>

						<b-row>
							<b-col sm="5" class="form-field"> Company: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								selDispatch.destination ? selDispatch.destination.company : ''
							}}</b-col>
						</b-row>
						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Warehouse: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								selDispatch.destination
								? selDispatch.destination.storageLocation
								: ''
							}}</b-col>
						</b-row>
						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Address: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								destinationLocation.address
							}}</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Date Received: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{
								showFormattedDate(selDispatch.dateReceived)
							}}</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="5" class="form-field"> Status: </b-col>
							<b-col sm="7" class="form-value-uppercase">{{ selDispatch.status }}</b-col>
						</b-row>

					</b-col>
				</b-row>

				<b-row class="my-5">
					<b-col sm="12">
						<table>
							<tr>
								<th scope="col" rowspan="2">ASSETS</th>
								<th scope="col">DISPATCH DETAILS</th>
								<th colspan="2" scope="colgroup">RECEIPT DETAILS</th>
							</tr>
							<tr>
								<th scope="col">TOTAL DISPATCHED</th>
								<th scope="col">TOTAL RECEIVED</th>
								<th scope="col">TOTAL DAMAGED</th>
							</tr>

							<tr v-for="item in selDispatch.assets" :key="item.assetTypeId">
								<td>{{ item.assetType }}</td>
								<td class="center"><strong>{{ item.expectedQuantity }}</strong></td>
								<td class="center"><strong>{{ item.actualQuantity }}</strong></td>
								<td class="center"><strong>{{ item.damagedQuantity }}</strong></td>
							</tr>

							<tr>
								<td><strong>TOTAL QUANTITY</strong></td>
								<td class="center"><strong>{{ getExpectedAssets() }}</strong></td>
								<td class="center"><strong>{{ getActualAssets() }}</strong></td>
								<td class="center"><strong>{{ getDamagedAssets() }}</strong></td>
							</tr>
						</table>

						<table class="table-remarks">
							<tr>
								<td>DISPATCH REMARKS:</td>
								<td>RECEIPT REMARKS:</td>
							</tr>
						</table>
					</b-col>
				</b-row>


				<b-row class="my-4">
					<b-col sm="6">
						<b-row class="mt-0">
							<b-col sm="4" class="form-sign-field"> Dispatched by: </b-col>
							<b-col sm="7">
								<b-row>
									<b-col class="form-sign-value">
										{{ getNameDisplay(deployedByUser) }}
									</b-col>
								</b-row>
								<b-row>
									<b-col class="form-sign-label">
										(Signature over Printed Name)
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</b-col>

					<b-col sm="6">
						<b-row class="mt-0">
							<b-col sm="4" class="form-sign-field"> Received by: </b-col>
							<b-col sm="7">
								<b-row>
									<b-col class="form-sign-value">
										{{ getNameDisplay(receivedByUser) }}
									</b-col>
								</b-row>
								<b-row>
									<b-col class="form-sign-label">
										(Signature over Printed Name)
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</b-col>
				</b-row>

				<b-row class="mt-4 mb-2 footer">
					<b-col sm="3" align="left">
						<img src="img/tawitech_logo_white.png" width="auto" height="35" />
					</b-col>

					<b-col class="footer-col" sm="9" align="right">
						Diezmo Rd., Brgy., Pulo, Cabuyao, Laguna, Philippines, 4025 | 02 519
						4042 | 0917 869 8576
					</b-col>
				</b-row>

			</div>

			<div class="slide-container" v-for="slideNo in getSlideNumber()" v-show="shouldRenderSlideWithPhotos(slideNo)"
				:id="`dispatch-summary-${slideNo + 1}`">

				<b-row class="my-2">
					<b-col sm="6">
						<div class="dispatch-header">DAMAGED ASSETS DETAILS</div>
						<div class="dispatch-id">
							{{ selDispatch.dispatchId }}
						</div>
						<div class="dispatch-date">
							{{ showFormattedDate(selDispatch.dateCreated) }}
						</div>
					</b-col>
					<b-col sm="6" align="right">
						<img v-img-orientation-changer src="img/logo.png" class="img-logo" alt="Ayun! Logo" />
					</b-col>
				</b-row>

				<b-row class="my-4" :key="`asset-pair-${index}`"
					v-for="(assets, index) in getAssetsInPairsStartAt(slideNo)">
					<b-col sm="6">
						<b-row no-gutters>
							<b-col sm="5" class="section-header"> {{ assets[0].assetType }} </b-col>
							<b-col sm="5">
								<hr />
							</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="3" class="form-field"> Damaged: </b-col>
							<b-col sm="8" class="form-value-uppercase">{{ assets[0].damagedQuantity }}</b-col>
						</b-row>

						<b-row class="mt-1">
							<b-col sm="3" class="form-field"> Images: </b-col>
							<b-col sm="8" class="form-value-uppercase mt-2">
								<tr>
									<td v-for="image in assets[0].images" class="no-border">
										<img class="damage-image" :src="image.url" alt="image.name" />
									</td>
								</tr>
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="3" class="form-field"> Remarks: </b-col>
							<b-col sm="8" class="form-value">{{ assets[0].damagedRemarks }}</b-col>
						</b-row>

					</b-col>

					<b-col sm="6" v-if="(assets[1])">
						<b-row no-gutters>
							<b-col sm="5" class="section-header"> {{ assets[1].assetType }} </b-col>
							<b-col sm="5">
								<hr />
							</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="3" class="form-field"> Damaged: </b-col>
							<b-col sm="8" class="form-value-uppercase">{{ assets[1].damagedQuantity }}</b-col>
						</b-row>

						<b-row class="mt-1">
							<b-col sm="3" class="form-field"> Images: </b-col>
							<b-col sm="8" class="form-value-uppercase mt-2">
								<tr>
									<td v-for="image in assets[1].images" class="no-border">
										<img class="damage-image" :src="image.url" alt="image.name" />
									</td>
								</tr>
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="3" class="form-field"> Remarks: </b-col>
							<b-col sm="8" class="form-value">{{ assets[1].damagedRemarks }}</b-col>
						</b-row>

					</b-col>
				</b-row>

				<b-row class="mt-4 mb-2 footer">
					<b-col sm="3" align="left">
						<img src="img/tawitech_logo_white.png" width="auto" height="35" />
					</b-col>

					<b-col class="footer-col" sm="9" align="right">
						Diezmo Rd., Brgy., Pulo, Cabuyao, Laguna, Philippines, 4025 | 02 519
						4042 | 0917 869 8576
					</b-col>
				</b-row>

			</div>

			<b-row class="mt-4 mb-2">

				<b-col sm="3" align="left" v-show="currentSlide === 0">
					<b-form-checkbox name="isDamagePhotosIncluded" v-model="isDamagePhotosIncluded">
						Include Damage Photo(s)
					</b-form-checkbox>
				</b-col>
			</b-row>

		</b-container>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// DAO
import userDAO from '@/database/users';
import storageLocationDAO from '@/database/storageLocations';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import html2canvas from 'html2canvas';

export default {
	name: 'print-dispatch-summary',
	components: {
		Loading,
	},
	data() {
		return {
			selDispatch: {},
			sourceLocation: {},
			destinationLocation: {},
			deployedByUser: {},
			receivedByUser: {},
			batchAssets: [],

			isLoading: false,
			isRendering: false,
			isDamagePhotosIncluded: false,

			// slide control
			currentSlide: 0,
			totalSlides: 0
		};
	},
	computed: {
		title() {
			return 'Print Dispatch ' + this.selDispatch.dispatchId;
		},
		disableConfirmButtons() {
			return this.isLoading;
		}
	},
	mounted() {
		EventBus.$on('onUpdateSelDispatch', async (selDispatch) => {
			this.onReset();
			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
				this.batchAssets = this.getAssetsInPairs();
				this.totalSlides = this.batchAssets.length;
			}

			await this.retrieveOtherDetails(selDispatch);
		});
	},
	methods: {
		getNameDisplay(user) {
			if (!_.isEmpty(user)) {
				return user.firstName + ' ' + user.lastName;
			}
			return '';
		},

		getSlideNumber() {
			return Array.from({ length: this.totalSlides }, (_, index) => index);
		},

		async retrieveOtherDetails(selDispatch) {
			// Show loader
			this.isLoading = true;

			this.sourceLocation =
				await storageLocationDAO.getStorageLocationById(
					selDispatch.source.storageLocationId
				);

			this.destinationLocation =
				await storageLocationDAO.getStorageLocationById(
					selDispatch.destination.storageLocationId
				);

			if (selDispatch.deployedBy && selDispatch.deployedBy.length > 0) {
				this.deployedByUser = await userDAO.getUserById(
					selDispatch.deployedBy
				);
			}

			if (selDispatch.receivedBy && selDispatch.receivedBy.length > 0) {
				this.receivedByUser = await userDAO.getUserById(
					selDispatch.receivedBy
				);
			}

			// Check for loader
			this.isLoading = false;
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getFileName() {
			let sourceCompany = this.selDispatch.source.company;
			let sourceLocation = this.selDispatch.source.storageLocation;
			let source = sourceCompany + '-' + sourceLocation;

			let destinationCompany = this.selDispatch.destination.company;
			let destinationLocation = this.selDispatch.destination.storageLocation;
			let destination = destinationCompany + '-' + destinationLocation;

			let dispatchId = this.selDispatch.dispatchId;
			return dispatchId + '_' + source + '_' + destination + '.png';
		},

		async handleDownload(event) {
			event.preventDefault();
			this.isLoading = true;
			this.isRendering = true;

			try {
				await this.renderAndDownload();
				this.$refs.modal.hide();
				this.onReset();
			} catch (error) {
				console.error('Error occurred:', error);
				// Handle the error appropriately
			} finally {
				this.isLoading = false;
			}
		},

		async renderAndDownload() {
			const filename = this.getFileName();

			if (this.isDamagePhotosIncluded) {
				const canvases = await this.renderCanvases();
				const mergedCanvas = this.mergeCanvases(canvases);
				const blob = await this.canvasToBlob(mergedCanvas);

				this.downloadBlob(blob, filename);
			} else {
				const containerElement = document.getElementById('dispatch-summary-0');
				const canvas = await html2canvas(containerElement);

				this.downloadCanvas(canvas, filename);
			}
		},

		async renderCanvases() {
			const canvases = [];

			for (let i = 0; i <= this.totalSlides; i++) {
				const containerElement = document.getElementById(`dispatch-summary-${i}`);
				const canvas = await html2canvas(containerElement, {
					useCORS: true, // Enable CORS to load external images
				});
				canvases.push(canvas);
			}

			return canvases;
		},

		mergeCanvases(canvases) {
			const mergedCanvas = document.createElement('canvas');
			const context = mergedCanvas.getContext('2d');

			const totalHeight = canvases.reduce((acc, canvas) => acc + canvas.height, 0);
			const { width, height: firstCanvasHeight } = canvases[0];

			mergedCanvas.width = width;
			mergedCanvas.height = totalHeight;

			let yOffset = 0;
			for (const canvas of canvases) {
				context.drawImage(canvas, 0, yOffset);
				yOffset += canvas.height;
			}

			return mergedCanvas;
		},

		canvasToBlob(canvas) {
			return new Promise((resolve) => {
				canvas.toBlob((blob) => {
					resolve(blob);
				});
			});
		},

		downloadBlob(blob, filename) {
			const link = document.createElement('a');
			link.setAttribute('download', filename);
			link.setAttribute('href', URL.createObjectURL(blob));
			link.click();
		},

		downloadCanvas(canvas, filename) {
			const link = document.createElement('a');
			link.href = canvas.toDataURL('image/png');
			link.download = filename;
			link.click();
		},

		getExpectedAssets() {
			let total = 0;
			if (this.selDispatch.assets) {
				for (const asset of this.selDispatch.assets)
					total += parseInt(asset.expectedQuantity);
			}
			return total;
		},

		getActualAssets() {
			let total = 0;
			if (this.selDispatch.assets) {
				for (const asset of this.selDispatch.assets)
					total += parseInt(asset.actualQuantity);
			}
			return total;
		},

		getDamagedAssets() {
			let total = 0;
			if (this.selDispatch.assets) {
				for (const asset of this.selDispatch.assets)
					if (asset.damagedQuantity) {
						total += parseInt(asset.damagedQuantity);
					}
			}
			return total;
		},

		getAssetsInPairs() {
			const assets = this.selDispatch.assets;
			const batchAssets = [];

			if (assets.length < 2) {
				batchAssets.push([assets[0], null]);
				return batchAssets;
			}

			for (let i = 0; i < assets.length; i += 2) {
				const batch = assets.slice(i, i + 2);
				batchAssets.push(batch);
			}

			return batchAssets;
		},

		getAssetsInPairsStartAt(slideNumber) {
			const assets = this.selDispatch.assets;
			const batchAssets = [];

			if (assets.length < 2) {
				batchAssets.push([assets[0], null]);
				return batchAssets;
			}

			const start = Math.max(0, (slideNumber - 1) * 4);
			const end = Math.min(start + 2, assets.length);

			for (let i = start; i < end; i += 2) {
				const batch = assets.slice(i, i + 2);
				batchAssets.push(batch);
			}

			return batchAssets;
		},

		shouldRenderSlideWithPhotos(slideNo) {
			const isCurrentSlide = this.currentSlide === slideNo && slideNo !== 0;
			const isRenderingWithPhotos = this.isRendering && this.isDamagePhotosIncluded;

			return isCurrentSlide || isRenderingWithPhotos;
		},

		previousSlide() {
			this.currentSlide = (this.currentSlide + this.totalSlides - 1) % this.totalSlides;
		},

		nextSlide() {
			this.currentSlide = (this.currentSlide + 1) % this.totalSlides;
		},

		onReset() {
			this.selDispatch = {};
			this.sourceLocation = {};
			this.destinationLocation = {};
			this.deployedByUser = {};
			this.receivedByUser = {};
			this.batchAssets = [];

			// Check for loader
			this.isLoading = false;
			this.isRendering = false;
			this.isDamagePhotosIncluded = false;

			// slide control
			this.currentSlide = 0;
			this.totalSlides = 0;
		}
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelDispatch');
	},
};
</script>

<style scoped>
.img-logo {
	margin-left: -30px;
	width: 32%;
}

.dispatch-header {
	text-transform: uppercase;
	color: #F18F01;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 16px;
}

.dispatch-id {
	text-transform: uppercase;
	color: #122c91;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	align-items: center;
	margin-top: 6px;
}

.dispatch-date {
	color: #484554;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	align-items: center;
	margin-top: 2px;
}

.footer {
	font-size: 12px;
	text-transform: capitalize;
	margin-top: 40px;
	color: white;
	background-color: #122c91;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.footer-col {
	padding-top: 0.7em;
}

.section-header {
	font-size: 13px;
	margin-top: 11px;
	line-height: 9px;
	color: #122c91;
	font-weight: bold;
	text-transform: uppercase;
	text-align: left !important;
}

.scan-to-view {
	font-weight: bold;
	text-transform: uppercase;
	vertical-align: bottom;
}

.form-field {
	font-size: 14px;
	text-align: right;
	text-transform: uppercase;
	line-height: 15px;
	color: #484554;
}

.form-value-uppercase {
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	text-transform: uppercase;
	line-height: 15px;
	color: #484554;
}

.form-value {
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	line-height: 15px;
	color: #484554;
}

.form-sign-field {
	font-size: 14px;
	text-transform: uppercase;
	line-height: 15px;
	color: #484554;
}

.form-sign-value {
	font-size: 14px;
	text-align: center;
	line-height: 15px;
	color: #484554;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 1px solid #D2D5D8;
}

.form-sign-label {
	font-size: 12px;
	text-align: center;
	color: #484554;
}

th {
	border: 1px solid #D2D5D8;
	text-align: center;
	padding: 0.5em;
	color: #122c91;
	font-weight: bold;
	text-transform: uppercase;
}

.center {
	text-align: center;
}

.no-border {
	border: none !important;
}

.damage-image {
	width: 100px;
	height: 100px;
}


/* Table CSS */

table {
	width: 100%;
	padding: 0.5em;
}

td {
	border: 1px solid #D2D5D8;
	padding: 0.5em;
}

.table-remarks {
	margin-top: 1em;
}

.table-remarks>tr>td {
	vertical-align: top;
	height: 6em;
}

/* Slide */

.slide-container {
	padding-top: 1.20em;
	padding-left: 1.20em;
	padding-right: 1.20em;
}

.slide-button {
	background-color: #2f353a;
	color: #ffffff;
	padding: 2px 8px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.slide-counter {
	margin: 0 10px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 12px;
}
</style>
