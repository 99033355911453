<template>
	<b-card>
		<b-tabs content-class="mt-3">
			<b-tab title="Primary Details" active>
				<b-card-text>
					<b-form-group label="Source:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<em>
							{{ sourceCompany }}
						</em>
						&nbsp;&nbsp;
						<strong>
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showDispatchLocation(row.item, 'source')" variant="success" class="mr-1">
								<em class="fa fa-map-marker fa-lg"></em>
							</b-button>
						</strong>
						{{ getCoordinates(row.item.source.geoaddress) }}
					</b-form-group>
					<b-form-group label="Destination:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<em>
							{{ destinationCompany }}
						</em>
						&nbsp;&nbsp;
						<strong>
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showDispatchLocation(row.item, 'destination')" variant="success" class="mr-1">
								<em class="fa fa-map-marker fa-lg"></em>
							</b-button>
						</strong>
						{{ getCoordinates(row.item.destination.geoaddress) }}
					</b-form-group>

					<b-form-group label="Notes:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<div v-if="row.item.notes">
							<div :ref="'div' + row.item.dispatchId" :style="{ width: '400px' }" class="truncate">
								{{ row.item.notes }}
							</div>
							<a v-if="isEllipsisActive('div' + row.item.dispatchId)" :ref="'a' + row.item.dispatchId"
								href="#" @click.prevent="
									toggleNotes(
										'div' + row.item.dispatchId,
										'a' + row.item.dispatchId
									)
									">
								See more
							</a>
						</div>
					</b-form-group>

					<b-form-group label="Date Created:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ showFormattedDate(row.item.dateCreated) }}
					</b-form-group>
					<b-form-group label="Created By:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getUserDisplay(row.item.createdBy) }}
					</b-form-group>

					<b-form-group label="Date Updated:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ showFormattedDate(row.item.dateUpdated) }}
					</b-form-group>
					<b-form-group label="Updated By:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getUserDisplay(row.item.updatedBy) }}
					</b-form-group>

					<b-form-group v-show="row.item.status === 'Cancelled'" label="Date Cancelled:" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ showFormattedDate(row.item.dateCancelled) }}
					</b-form-group>
					<b-form-group v-show="row.item.status === 'Cancelled'" label="Cancelled By:" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ getUserDisplay(row.item.cancelledBy) }}
					</b-form-group>

					<b-form-group v-show="row.item.status === 'Received'" label="Date Received:" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ showFormattedDate(row.item.dateReceived) }}
					</b-form-group>
					<b-form-group v-show="row.item.status === 'Received'" label="Received By:" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						{{ getUserDisplay(row.item.receivedBy) }}
					</b-form-group>
				</b-card-text>
			</b-tab>

			<b-tab title="Transportation">
				<b-card-text>
					<b-row class="mb-2">
						<b-col sm="7">
							<b-form-group label="Plate No/CR/OR:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.transportation.plateNo }}
							</b-form-group>

							<b-form-group label="Company:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.transportation.company }}
							</b-form-group>

							<b-form-group label="Driver:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.driver.name }}
							</b-form-group>

							<b-form-group label="Assistants:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.driver.assistants ? row.item.driver.assistants : "-" }}
							</b-form-group>
						</b-col>
						<b-col sm="3" v-if="hasImage(row.item.driver.license)">
							<b-img v-img-orientation-changer :src="row.item.driver.license.url" alt="Responsive image"
								thumbnail fluid />
							<a href="#" class="pull-left" @click.prevent="showImage(row.item.driver)">
								Click to view full image
							</a>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

			<b-tab title="Assets">
				<b-card>
					<b-form-group label="Coverage" label-class="font-weight-bold pt-0" label-align-sm="left">
						<b-progress :max="totalExpected" height="1rem">
							<b-progress-bar :value="totalActual">
								<span>
									<strong>{{ totalActual }} / {{ totalExpected }}</strong>
								</span>
							</b-progress-bar>
						</b-progress>
					</b-form-group>

					<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" responsive>
						<template v-slot:cell(dateCreated)="row">
							{{ showFormattedDate(row.item.dateCreated) }}
						</template>
						<template v-slot:cell(reasonForUpdate)="row">
							{{ row.item.new.notes }}
						</template>
					</b-table>

					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="totalDisplay">Total: {{ totalRows }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
								class="my-0" size="sm" />
						</b-col>
					</b-row>
				</b-card>
			</b-tab>
		</b-tabs>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<em class="icon-arrow-up"></em>
				</b-button>
			</b-col>
		</b-row>

		<InvalidImageDialog />
	</b-card>
</template>

<script>
// Component
import InvalidImageDialog from '../common/InvalidImageDialog';

// Util
import { DateUtil } from '@/utils/dateutil';
import { LocationUtil } from '@/utils/locationUtil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
	name: 'dispatch-details-view',
	props: {
		row: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'name',
					label: 'Asset Type',
					sortable: true,
				},
				{
					key: 'expected',
					label: 'Expected',
				},
				{
					key: 'actual',
					label: 'Actual',
				},
				{
					key: 'damaged',
					label: 'Damaged',
				},
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			totalExpected: 0,
			totalActual: 0,
		};
	},
	components: {
		InvalidImageDialog,
	},
	computed: {
		sourceCompany() {
			return DispatchUtil.getCompanyLocationDisplay(this.row.item.source);
		},
		destinationCompany() {
			return DispatchUtil.getCompanyLocationDisplay(this.row.item.destination);
		},
		geoaddress() {
			if (this.row.item.proofOfReceipt)
				return this.row.item.proofOfReceipt.geoaddress;
			else return {};
		},
	},

	async mounted() {
		let item = this.row.item;
		let assetsObj = {};

		if (!_.isEmpty(item.assets)) {
			_.forEach(item.assets, (asset) => {
				let assetEntry = {
					name: asset.assetType,
					expected: asset.expectedQuantity,
					actual: asset.actualQuantity,
					damaged: asset.damagedQuantity,
				};

				this.totalExpected += parseInt(asset.expectedQuantity);
				this.totalActual += parseInt(asset.actualQuantity);

				assetsObj[asset.assetTypeId] = assetEntry;
			});
		}

		this.items = Object.values(assetsObj);
		this.totalRows = this.items.length;
	},

	methods: {
		getUserDisplay(userId) {
			let userObj = this.allUsersObj[userId];

			if (!_.isEmpty(userObj)) {
				return userObj.firstName + ' ' + userObj.lastName + ' (' + userId + ')';
			}
			return userId;
		},

		getCoordinates(geoaddress) {
			geoaddress = LocationUtil.getGeoaddress(geoaddress);

			return (
				'(' +
				geoaddress.latitude.toFixed(6) +
				', ' +
				geoaddress.longitude.toFixed(6) +
				')'
			);
		},

		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},

		getDisplayValue(value) {
			if (!value || value.length === 0) {
				return '-';
			}
			return value;
		},

		showDispatchLocation(details, endpoint) {
			const params = {
				source: details.source,
				destination: details.destination,
				endpoint,
			};

			EventBus.$emit('onUpdateSelDispatchLocationView', params);
			this.$bvModal.show('dispatch-location-view');
		},

		openImage(imageLink) {
			EventBus.$emit('onUpdateSelProofReceiptImageView', {
				dispatchId: this.row.item.dispatchId,
				imageLink: imageLink,
				latitude: this.geoaddress.latitude ? this.geoaddress.latitude : 0.0,
				longitude: this.geoaddress.longitude ? this.geoaddress.latitude : 0.0,
				dateApproved: this.row.item.proofOfReceipt
					? this.row.item.proofOfReceipt.dateApproved
					: '',
			});
			this.$bvModal.show('receipt-image-view');
		},

		showCapturedImageLocation() {
			EventBus.$emit(
				'onUpdateSelProofReceiptImageLocationView',
				this.geoaddress
			);
			this.$bvModal.show('proof-receipt-image-location-view');
		},

		toggleNotes(divRefId, aRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let className = elementArray[0].className;
					if (className === 'truncate') {
						this.$refs[divRefId][0].className = null;
						this.$refs[aRefId][0].text = 'See less';
					} else {
						this.$refs[divRefId][0].className = 'truncate';
						this.$refs[aRefId][0].text = 'See more';
					}
				} else {
					let className = elementArray.className;
					if (className === 'truncate') {
						this.$refs[divRefId].className = null;
						this.$refs[aRefId].text = 'See less';
					} else {
						this.$refs[divRefId].className = 'truncate';
						this.$refs[aRefId].text = 'See more';
					}
				}
			}
		},
		isEllipsisActive(divRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let element = elementArray[0];
					return element.offsetWidth < element.scrollWidth;
				} else {
					return elementArray.offsetWidth < elementArray.scrollWidth;
				}
			}
			return false;
		},

		showImage(driver) {
			EventBus.$emit('onSelectImageView', {
				url: driver.license.url,
				name: driver.license.name
			});
			this.$bvModal.show('image-view-dialog');
		},

		hasImage(media) {
			return media && media.url && media.url.length > 0;
		},
	},
};
</script>

<style scoped></style>



